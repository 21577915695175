<template>
  <v-container
    fluid
    class="custom_bg"
  >
    <Appbar v-if="isAuthenticated" />
    <div class="not-found">
      <img
        src="@/assets/png/404.png"
        alt="404 Illustration"
        class="error-image"
      >
      <h1>{{ $t('notFoundPage.title') }}</h1>
      <p>{{ $t('notFoundPage.description') }}</p>
      <p v-if="!isAuthenticated">
        {{ $t('notFoundPage.loginSuggestion') }} <router-link :to="{ name: 'Login' }">
          {{ $t('logintoyouraccount') }}
        </router-link>
      </p>
      <v-btn
        color="primary"
        class="mt-4"
        @click="goBack()"
      >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        {{ $t('notFoundPage.backButton') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Appbar from "@/components/Layout/Appbar/Index.vue";

const { mapGetters } = createNamespacedHelpers('user');

export default {
  name: "NotFound",
  components: {
    Appbar,
  },
  data() {
    return {
      previousRoute: null
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    goBack() {
      // Check if the previous route was part of the app (check if it had matched components)
      if (this.previousRoute && this.previousRoute.name) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    }
  },
  // This will capture the previous route when the component is first loaded
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previousRoute = from;
    });
  },
  // This will capture the previous route when navigating between routes within the app
  beforeRouteUpdate(to, from, next) {
    this.previousRoute = from;
    next();
  }
};
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 50px;
  max-width: 600px;
  margin: 0 auto;
}

.error-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 16px;
}

p {
  margin-bottom: 24px;
  text-align: center;
}
</style>
